
import { Vue, Component, Watch } from 'vue-property-decorator'
import UpdateAppVersionDropdown from '@/app/ui/views/UpdateAppVersion/components/UpdateAppVersionDropdown/index.vue'
import UpdateAppVersionInput from '@/app/ui/views/UpdateAppVersion/components/UpdateAppVersionInput/index.vue'
import UpdateAppVersionDateTimePicker from '@/app/ui/views/UpdateAppVersion/components/UpdateAppVersionDateTimePicker/index.vue'
import * as constantsData from '@/app/infrastructures/misc/Constants/updateAppVersion'
import Button from '@/app/ui/components/Button/index.vue'
import { required, ValidationRule } from 'vuelidate/lib/validators'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import controller from '@/app/ui/controllers/UpdateAppVersionController'
import { Utils } from '@/app/infrastructures/misc'
import { DropDownLabelValue } from '@/app/ui/components/DropdownSelect/interface'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'

interface ValidationsInterface {
  form: {
    operationSystem: {
      required: () => ValidationRule
    }
    updateType: {
      required: () => ValidationRule
    }
    appVersion: {
      required: () => ValidationRule
    }
    versionValidation: {
      required: () => ValidationRule
    }
    publishDate: {
      required: () => ValidationRule
    }
    publishTime: {
      required: () => ValidationRule
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    LoadingOverlay,
    Modal,
    Button,
    UpdateAppVersionDropdown,
    UpdateAppVersionInput,
    UpdateAppVersionDateTimePicker,
    ModalAction,
  },
})
export default class UpdateAppVersionCreate extends Vue {
  controller = controller
  constantsData = constantsData
  showModalConfirm = false
  showModalSuccess = false
  valueDateAndTime = '-'

  form: {
    operationSystem: DropDownLabelValue<string> | null
    updateType: DropDownLabelValue<string> | null
    appVersion: string
    versionValidation: DropDownLabelValue<string> | null
    publishDate: Date | undefined
    publishTime: Date | undefined
  } = {
    operationSystem: null,
    updateType: null,
    appVersion: '',
    versionValidation: null,
    publishDate: undefined,
    publishTime: undefined,
  }

  modalActionData = {
    isCancel: false,
    title: '',
    description: '',
    textPrimary: '',
    textSecondary: '',
  }

  @Validations()
  validations(): ValidationsInterface {
    return {
      form: {
        operationSystem: { required },
        updateType: { required },
        appVersion: { required },
        versionValidation: { required },
        publishDate: { required },
        publishTime: { required },
      },
    }
  }

  getPublishDateAndTime(
    publishDate: Date | undefined,
    publishTime: Date | undefined
  ): string {
    let chooseDate = '-'
    if (publishDate && publishTime) {
      const theDate = Utils.setFormatDateTime(
        publishDate,
        publishTime.getHours(),
        publishTime.getMinutes()
      )
      chooseDate = Utils.formatDate(theDate, 'DD MMMM YYYY HH:mm')
    }

    return chooseDate
  }

  doActionModal(): void {
    const todayDate = new Date()
    if (this.form.publishDate && this.form.publishTime) {
      const chooseDate = Utils.setFormatDateTime(
        this.form.publishDate,
        this.form.publishTime.getHours(),
        this.form.publishTime.getMinutes()
      )
      if (new Date(chooseDate) < todayDate) {
        this.$notify({
          title: 'Create New Update App Version Failed',
          text: 'Publish date and time must be a later date and time than now',
          type: 'error',
          duration: 3000,
        })
        return
      }

      this.showModalConfirm = false
      controller.create({
        operationSystem: <string>this.form.operationSystem?.value,
        updateType: <string>this.form.updateType?.value,
        appVersion: this.form.appVersion,
        versionValidation: <string>this.form.versionValidation?.value,
        publishDateTime: chooseDate,
      })
    }
  }

  @Watch('controller.isSave')
  onWatchSave(isSuccess: boolean): void {
    if (isSuccess) {
      this.showModalConfirm = false
      this.showModalSuccess = true
    }
  }

  doCancel(): void {
    this.$router.push('/update-app-version')
  }

  showModalSubmit(): void {
    this.modalActionData = {
      isCancel: false,
      title: 'Want to Schedule an Update Version?',
      description: '',
      textPrimary: 'Yes, Create',
      textSecondary: 'Check Again',
    }
    this.showModalConfirm = true
    this.valueDateAndTime = this.getPublishDateAndTime(
      this.form.publishDate,
      this.form.publishTime
    )
  }

  onCloseSuccessModal(): void {
    this.showModalSuccess = false
    this.$router.push('/update-app-version')
  }

  onInputDate(chooseDate: Date | undefined): void {
    this.form.publishDate = chooseDate
  }

  onInputTime(chooseTime: Date): void {
    this.form.publishTime = chooseTime
  }
}
